<template>
  <div id="index-container">
    <Head />
    <div
      style="display: flex; justify-content: center; align-items: center; height: 100vh;"
    >
      <video
        ref="myVideo"
        autoplay
        loop
        muted
        controls
        style="width: 90%; height: 250px;"
        @click="playVideo"
      >
        <source src="../../../public/1.mp4" type="video/mp4" />
      </video>
    </div>

    <!-- <van-image class="mrtop banner" v-for="(i, k) in banner" :key="k" fit="cover" :src="imgUrl + i.pic" /> -->
    <!-- <van-swipe class="home-swipe" :autoplay="5000" indicator-color="white">
      <van-swipe-item v-for="(i, k) in banner" :key="k">
        <van-image fit="cover" class="home-swipe-img" :src="imgUrl + i.pic" />
      </van-swipe-item>
      <van-swipe-item v-for="(i, k) in banner" :key="k">
        <van-image fit="cover" class="home-swipe-img" :src="imgUrl + i.pic" />
      </van-swipe-item>
      <van-swipe-item v-for="(i, k) in banner" :key="k">
        <van-image fit="cover" class="home-swipe-img" :src="imgUrl + i.pic" />
      </van-swipe-item>
    </van-swipe> -->
  </div>
</template>

<script>
import Head from "@/components/head";
export default {
  data() {
    return {
      imgUrl: "http://img.kar-studio.com",
      banner: [],
    };
  },
  components: {
    Head,
  },
  computed: {},
  methods: {
    playVideo() {
      const video = this.$refs.myVideo;
      video.play().catch((error) => {
        // Autoplay was prevented
        // You can show a UI element to let users manually start the video
        console.error("Autoplay was prevented: ", error);
      });
    },
    getBannerList() {
      this.$api.bannerList().then((res) => {
        if (res.data.status) {
          this.banner = res.data.data;
        }
      });
    },
    getMenuLink() {
      this.$api.menuLink().then((res) => {
        if (res.data.status) {
          this.link = res.data.data;
        }
      });
    },
  },
  created() {
    this.getBannerList();
    this.getMenuLink();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.mrtop {
  margin-top: 140px;
}
.banner {
  width: 90%;
  margin-bottom: 40px;
}
.home-swipe {
  height: 100vh;
  .home-swipe-img {
    height: 100%;
  }
}
</style>
